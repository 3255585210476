@import '../index.scss';

main#portfolio {
    margin: 0;
    background: linear-gradient(180deg, #FED9B7 0%, #FDFCDC 100%);
    position: relative;
    height: 150vh;

    .portfolio-hero {
        background-color: $Bitter-Sweeter;
        position: relative;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.4;
        }

        h3 {
            padding: 12em 0;
            font-size: 1.4em;
        }
    }

    h3 {
        text-align: center;
        color: white;
        letter-spacing: 8px;
    }

    .container-portfolio {
        width: 100%;
        padding-top: 10em;
    }

    h1 {
        text-align: center;
        text-shadow: 3px 1px 0px rgba(255, 255, 255, 1);
    }

    section.portfolio-content {
        background: transparent;

        .container-portfolio {
            display: grid;
            padding-top: 5em;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            grid-template-rows: 1fr 1fr;
            gap: 2em;


            article.cards-portfolio {
                width: 100%;

                .card {
                    position: relative;

                    img {
                        width: 100%;
                        height: 25em;
                        object-fit: cover;
                    }

                    .content {
                        background-color: rgba(0, 0, 0, 0.7);
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        bottom: 0;
                        text-align: center;

                        h3 {
                            font-size: 1.8em;
                            color: white;
                            text-shadow: 3px 1px 0px rgba(0, 0, 0, 1);
                            text-align: center;
                            margin-top: 1em;
                        }

                        p,
                        span {
                            color: white;
                            margin-top: 2em;
                            text-align: center;
                        }


                    }
                }
            }

        }
    }

    .buttons {
        margin-top: 2em;
        width: 100%;
    }

    button {
        background-color: transparent;
        display: block;
        width: 60%;
        margin: 1em auto;
        padding: 1em 0;
        border-radius: 15px;
        border: 2px solid white;
        color: white;
        transition: all 0.5s;

        a {
            color: white;
            text-decoration: none;
            font-size: 1.4em;
        }

        &.git-btn {
            background-color: black;


            &:hover a {
                color: black;
            }

            &:hover {
                background-color: white;

            }
        }

        &.site-btn {
            background-color: $Bitter-Sweeter;

            &:hover {
                background-color: $Bitter-Sweeter-hover;
            }
        }


    }

    .footer-parallax {
        top: 40em;
    }
}

@media (min-width: 768px) {
    main#portfolio {
        section.portfolio-content {
            height: 100%;

            article.cards-portfolio {
                height: 50%;
            }
        }
    }
}

@media (min-width: 1300px) {
    main#portfolio {
        section.portfolio-content {
            width: 70%;
            margin: 0 auto;
            padding-top: 8em;
        }
    }
}