@import '../index.scss';

/*Mobile & Tablet*/
.header {
	background-color: transparent;
	width: 100%;
	height: 10vh;
	position: fixed;
	z-index: 999;
	transition: all 0.5s;
}

.container {
	width: 100%;
	height: 100%;
}

.hamburger {
	position: absolute;
	top: 1em;
	right: 0.5em;
	color: black;
	z-index: 999;

	.hamburger-inner,
	.hamburger-inner::before,
	.hamburger-inner::after {
		background-color: white;
		border: solid 1px black;
	}
}

.whiteLines,
.whiteLines::before,
.whiteLines::after {
	background-color: white !important;
}

.navbar {
	z-index: 10;
	position: fixed;
	left: 0;
	align-items: center;
	height: 100%;
	width: 100%;
	margin: 0;
	background: rgba(2, 2, 2, 0.53);
	transition: all 0.5s ease-in-out;
	transform: translate(100%);

	ul {
		list-style-type: none;
		height: 100%;
		width: 80%;
		margin: auto auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0;
		gap: 1em;

		h3 {
			color: white;
		}

		a {
			font-family: $font-titles;
			font-weight: 600;
			text-decoration: none;
			color: white;
		}
	}
}

.active-nav {
	transform: translateX(0);
}

.icons {
	margin-top: 2em;
	width: 100%;
	display: flex;
	flex-direction: row;

	a {
		margin: 0 auto;
	}
}

/* Tablet */
@media (min-width: 768px) {
	.icons {
		width: 60%;
		margin: 1em auto;
	}
}

/* desktop*/
@media (min-width: 1024px) {
	.header {
		height: 12vh;
	}

	.container {
		width: 90%;
		margin: 0 auto;
		height: 100%;
	}

	.hamburger {
		display: none !important;
	}

	.navbar {
		position: inherit;
		align-items: center;
		height: 100%;
		transform: none;
		background: transparent;
		color: black;
		display: flex;
		flex-direction: row;

		ul {
			list-style: none;
			height: 100%;
			margin: auto auto;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			padding: 0px;
			gap: 1em;

			a {
				font-family: $font-titles;
				font-weight: 600;
				font-size: 1.5em;
				color: black;
				transition: all 0.5s ease-in-out;
			}

			a:hover {
				color: grey;
				transform: rotate(10deg) scale(1.1);
			}
		}
	}

	.icons {
		display: none;
	}

	.icons-desktop {
		display: flex;
		flex-direction: row;
		gap: 1em;

		img {
			width: 3em;

			&:hover {
				transform: scale(1.1);
				opacity: 0.8;
			}
		}
	}
}
