@import '../index.scss';

main.blog-section {
    background: linear-gradient(180deg, #FED9B7 0%, #FDFCDC 100%);
    padding-top: 0;
    height: 100vh;



    .blog-hero {
        background-color: $CG-blue;
        position: relative;
        z-index: 0;
        position: sticky;

        img {
            width: 100%;
            height: 100%;
            filter: grayscale(1.1);
            object-fit: cover;
            opacity: 0.4;
            position: absolute;

        }

        h3 {
            padding: 12em 0;
            font-size: 1.4em;
        }


    }

    h3 {
        color: rgba(255, 255, 255, 0.7);
        letter-spacing: .5em;
        text-align: center;
        display: block;
        padding: 7em 0;
        text-decoration: none;
        font-size: 15pt;
        font-weight: 700;
        text-transform: uppercase;
    }


    .parallax-posts {
        z-index: 999;
    }
}


.articles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
    grid-template-rows: 1fr 1fr 1fr;
    margin-top: 2em;
    gap: 2em;
    margin-bottom: 21em;

    .article-post {
        position: relative;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    span {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(3, 3, 3, 0.7);
        width: 100%;

        h3 {
            text-align: center;
            padding: 1em 0;
            color: white;
        }
    }
}

@media (min-width: 1024px) {
    .articles {
        padding-top: 16em;
    }
}

@media (min-width: 1300px) {
    main.blog-section {
        .container-mobile {
            width: 70%;
        }
    }
}

@media (min-height: 640px) {
    .articles {
        margin-bottom: 19.5em;
    }
}

@media (min-height: 812px) {
    .articles {
        margin-bottom: 45em;
    }
}