@import '../index.scss';

footer.footer {
	width: 100%;
	height: 50vh;
	background-color: #4c5068;
	position: relative;
	overflow: hidden;
	margin-top: 2em;

	.footerpng {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 0.2;
		filter: grayscale(1.2);
	}

	.container-links {
		padding-top: 5rem;
	}

	p {
		text-align: center;
		color: white;
		padding-top: 1em;
	}

	.icons-footer {
		margin-top: 2.5em;
		display: flex;
		justify-content: space-around;
		flex-direction: row;
	}
}

footer ul {
	width: 100%;
	height: 100%;
	padding-top: 2%;
	list-style-type: none;

	li {
		text-align: center;
		padding-top: 1em;

		a {
			text-decoration: none;
			color: white;
		}
	}
}

@media (min-width: 1024px) {
	footer.footer {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-top: 28em;

		.container-links {
			padding-top: 2em;
			width: 60%;
			margin: 0 auto;
		}

		p {
			padding-top: 2em;
		}

		svg {
			padding-top: 0;
			width: 60%;
			z-index: 999;
			height: 70%;
		}

		.icons-footer {
			margin-top: 2.5em;
			display: flex;
			justify-content: space-around;
			flex-direction: row;
		}
	}
}
