@import '../index.scss';


section#about {
    z-index: 999;
    padding-top: 10em;
    //background-color: $Second-Base-Peach-Color;


    .container-about {
        width: 95%;
        margin: 0 auto;
    }

    .profile-pic-text {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        position: relative;

        img {
            width: 50%;
        }

        h2 {
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            /* Firefox */
            -moz-transform: rotate(90deg);
            /* IE */
            -ms-transform: rotate(90deg);
            /* Opera */
            -o-transform: rotate(90deg);
            /* Internet Explorer */
            filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
            position: absolute;
            left: -1em;
            top: 2.4em;
            text-shadow: 3px 1px 0px rgba(255, 255, 255, 1);
        }
    }

    .text-content {
        width: 100%;

        p {
            text-align: center;
            margin-bottom: 1em;
            margin-top: 2em;
            line-height: 2em;
            font-weight: 600;
            letter-spacing: 2px;
        }

        p:last-of-type {
            text-align: center;
        }
    }

    .services {
        width: 85%;
        margin: 0 auto;
        padding-bottom: 1.5em;

        .service {
            height: 100%;
            width: 100%;
            margin: 1em 0;
            padding: 1em 0;
            border: 2px solid black;
            border-radius: 2em;
            background-color: white;
            z-index: 999;
            position: relative;
            overflow: hidden;

            img {
                display: block;
                width: 90%;
                margin: 0 auto;
                position: absolute;
                top: 0;
                opacity: 0.3;
                left: -5em;
            }

            h3 {
                margin-top: 1em;
                text-align: center;
                font-size: 1.6em;
                font-weight: 700;
                letter-spacing: 2px;
                padding: 2.3em 0;
                color: black;
            }
        }
    }
}

/* Tablet Responsive */
@media (min-width: 768px) {
    section#about {
        padding-top: 24em;
        height: 135%;

        .profile-pic-text {

            img {
                width: 35%;
            }

            h2 {
                font-size: 2.5em;
            }
        }

        .text-content {
            p {
                font-size: 1.5em;
            }
        }

        .services {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 1em;
            position: relative;

            .service {

                h3 {
                    width: 100%;
                    font-size: 2em;
                    margin: 0 auto;
                    margin-top: 1em;
                }
            }
        }
    }
}

/* Desktop Responsive */

@media (min-width: 1024px) {
    .about .container-mobile {
        width: 98%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .info-content {
        width: 45%;
        transform: translateY(-18em);
    }

    section#about {
        .profile-pic-text {

            h2 {
                top: 1.5em;
            }
        }

        .container-about {
            display: flex;
            flex-direction: row;
        }

        .text-content {
            width: 75%;
            margin: 1em auto;

            p {
                font-size: 1.3em;
            }
        }

        .services {
            width: 60%;
            height: 100%;

            .service {
                img {
                    top: 4em;
                    width: 135%;
                    left: -5em;
                }
            }
        }

        .services .service h3 {
            font-size: 1.5em;
            text-shadow: none;
            padding: 5em 0;
            color: black;
        }
    }

    .box1 {
        transform: translateY(8em);
    }

    .box3 {
        transform: translateY(-8em);
    }
}