@import '../index.scss';

main#contact-site {
    background: linear-gradient(180deg, #FED9B7 0%, #FDFCDC 100%);
    height: 140vh;
    width: 100%;
    position: relative;

    .contact-hero {
        background-color: $Verdi-gris-hover;
        position: relative;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.4;
            filter: grayscale(1.1);
        }

        h3 {
            padding: 12em 0;
            font-size: 1.4em;
        }
    }

    h3 {
        color: rgba(255, 255, 255, 0.7);
        letter-spacing: .5em;
        text-align: center;
        display: block;
        padding: 7em 0;
        text-decoration: none;
        font-size: 15pt;
        font-weight: 700;
        text-transform: uppercase;
    }

    .form-complete {
        position: relative;
        overflow: hidden;
        background-color: white;

        .cohete-opacity {
            position: absolute;
            right: -15em;
            width: 100%;
            height: 100%;
            opacity: 0.1;
            z-index: 0;
        }

        .image-form {
            display: none;
        }
    }

    .footer-contact {
        margin-top: 50%;
    }
}

form.contact-form {
    margin-top: 2em;
    padding: 2em 2em;
    z-index: 99;

    h2 {
        text-align: center;
        margin-bottom: 1em;
    }

    input {
        width: 100%;
        padding: 0.4em 0;
        appearance: unset;
        border-style: solid;
        border-width: 1px;
        border-radius: 5px;
    }

    textarea {
        width: 100%;
        height: 6em;
        resize: none;
        appearance: unset;
        border-style: solid;
        border-width: 1px;
        border-radius: 10px;
    }

    button {
        margin-top: 1em;
        width: 100%;
        padding: 1em 0;
        color: black;
        background-color: transparent;
        transition: all 0.5s;
        appearance: unset;
        border-style: solid;
        border-width: 1.5px;
        border-radius: 25px;
        border-color: black;
    }

    button:hover {
        background-color: #B3554E;
        color: white;
    }
}

form label {
    display: block;
    margin-bottom: 0.5em;

}

@media (min-width: 768px) {
    main#contact-site {
        .black-space-contact {
            h3 {
                padding-top: 7em;
            }
        }
    }

    form.contact-form {
        width: 80%;
        margin: 2em auto;
    }
}

@media (min-width: 1024px) {
    main#contact-site {
        .form-complete {
            display: flex;
            flex-direction: row;
            width: 65%;
            margin: 0 auto;

            .image-form {
                display: initial;
                width: 45%;
                height: auto;
                object-fit: cover;
            }
        }
    }

    form.contact-form {
        width: 100%;
        height: 100%;
        margin: 0;
    }
}

@media (min-width: 1440px) {
    main#contact-site {
        .footer-contact {
            margin-top: 20%;
        }
    }
}