@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;500;600;700;800&family=Open+Sans&display=swap');
@import '/node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';

/* Fonts */

$font-titles: 'Assistant', sans-serif;
$font-texts: 'Open Sans', sans-serif;

/* Colors */

$CG-blue: #0081a7;
$CG-blue-hover: #004559;
$Verdi-gris: #00afb9;
$Verdi-gris-hover: #007076;
$base-color: #fdfcdc;
$base-color-hover: #dad9b6;
$Second-Base-Peach-Color: #fed9b7;
$Second-Base-Peach-Color-hover: #dda16b;
$Bitter-Sweeter: #f07167;
$Bitter-Sweeter-hover: #b3554e;

/* Rules */
.container {
	width: 80%;
	margin: 0 auto;
}

.container-mobile {
	width: 100%;
	margin: 0 auto;
}

* {
	font-family: $font-texts;
}

* {
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}

a {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: $font-titles;
}

h1 {
	font-weight: 700;
	font-size: 42px;
}

h2 {
	font-weight: 600;
	font-size: 30px;
}

h3 {
	font-weight: 600;
	font-size: 24px;
}

h4 {
	font-weight: 600;
	font-size: 18px;
}

h5 {
	font-weight: 300;
	font-size: 16px;
}
